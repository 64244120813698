<template>
  <tr>
    <td v-if="day">{{day}}</td>
    <td>{{label}}</td>
    <td class="time">
      <div class="select">
        <select v-model="openingHour.OpenMinutes">
          <option
            v-for="(option, index) in timeOptions"
            v-bind:value="option"
            :key="'opt' + index"
            :selected="openingHour.OpenMinutes === option"
          >{{option | minutesToTime }}</option>
        </select>
      </div>
      -
      <div class="select">
        <select v-model="openingHour.CloseMinutes">
          <option
            v-for="(option, index) in timeOptions"
            v-bind:value="option"
            :key="'opt' + index"
            :selected="openingHour.CloseMinutes === option"
          >{{option | minutesToTime }}</option>
        </select>
      </div>
    </td>
    <td>
      <ui-checkbox v-model="openingHour.Open" />
    </td>
  </tr>
</template>

<script>
export default {
  props: ['openingHour', 'timeOptions', 'label', 'day']
}
</script>
